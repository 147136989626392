#logo-img {
  position: absolute;
  transition: 0.4s;
  z-index: 10000;
  left: 50vw;
  top: 40vh;
  width: 30vh;
  height: 30vh;
  transform: translateX(-50%) translateY(-50%);
}

#logo-img.active {
  left: 0;
  top: 0;
  transform: translateX(0%) translateY(0%);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #logo-img.active {
    width: 3rem;
    height: 3rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #logo-img.active {
    width: 3.5rem;
    height: 3.5rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #logo-img.active {
    width: 3.5rem;
    height: 3.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #logo-img.active {
    width: 4rem;
    height: 4rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #logo-img.active {
    width: 4.5rem;
    height: 4.5rem;
  }
}