#headerNavBtn {
  font-family: "Courier New", Courier, monospace;
  background-color: rgba(0,0,0,0);
  height: 100%;
  width: 100%;
  border: none;
  width: auto;
  color: rgba(211,211,211,0);
  transition: 0.4s;
  cursor: pointer;
}

#headerNavBtn.active {
  font-size: 13px;
  color: rgba(211,211,211,1);
}

#headerNavBtn.clicked,
#headerNavBtn:hover {
  color: rgba(191,26,4,1);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #headerNavBtn {
    font-size: 1rem;
    min-width: 10px;
    width: auto;
  }

  #headerNavBtn.active {
    font-size: 0.7rem;
    width: auto;
  }

  #headerNavBtn.clicked,
  #headerNavBtn:hover {
    font-size: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #headerNavBtn {
    font-size: 1.5rem;
    min-width: 10px;
    width: auto;
    margin: 0 0.5rem;
  }

  #headerNavBtn.active {
    font-size: 1rem;
    width: auto;
  }

  #headerNavBtn.clicked,
  #headerNavBtn:hover {
    font-size: 1.5rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #headerNavBtn {
    font-size: 1.5rem;
    min-width: 10px;
    margin: 0 0.5rem;
    width: auto;
  }

  #headerNavBtn.active {
    font-size: 1rem;
    width: auto;
  }

  #headerNavBtn.clicked,
  #headerNavBtn:hover {
    font-size: 1.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #headerNavBtn {
    font-size: 2rem;
    min-width: 10px;
    width: auto;
    margin: 0 1rem;
  }

  #headerNavBtn.active {
    font-size: 1.2rem;
    width: auto;
  }

  #headerNavBtn.clicked,
  #headerNavBtn:hover {
    font-size: 2rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #headerNavBtn {
    font-size: 2.5rem;
    min-width: 10px;
    width: auto;
    margin: 0 1rem;
  }

  #headerNavBtn.active {
    font-size: 1.4rem;
    width: auto;
  }

  #headerNavBtn.clicked,
  #headerNavBtn:hover {
    font-size: 2.5rem;
  }
}