#navBtn {
  font-family: "Courier New", Courier, monospace;
  background-color: rgba(0,0,0,0);
  border-top: none;
  border-left: none;
  border-right: none;
  color: rgba(211,211,211,1);
  width: 100%;
  height: 100%;
  transition: 0.4s;
  cursor: pointer;
  margin: 0 0.3rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #navBtn {
    font-size: 1rem;
    min-width: 5rem;
    width: 5rem;
    border-bottom: 1px solid #ddd;
  }

  #navBtn.active {
    color: rgba(211,211,211,0);
    width: 30vw;
    border-bottom: 3px solid #ddd;
  }
  
  #navBtn:hover {
    border-bottom: 3px solid #bf1a04;
    color: rgba(191,26,4,1);
  }
  
  #navBtn.clicked {
    border-bottom: 3px solid #bf1a04;
    color: rgba(191,26,4,0);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #navBtn {
    font-size: 1.5rem;
    min-width: 5.5rem;
    width: 7.5rem;
    border-bottom: 2px solid #ddd;
  }

  #navBtn.active {
    color: rgba(211,211,211,0);
    width: 30vw;
    border-bottom: 4px solid #ddd;
  }
  
  #navBtn:hover {
    border-bottom: 4px solid #bf1a04;
    color: rgba(191,26,4,1);
  }
  
  #navBtn.clicked {
    border-bottom: 4px solid #bf1a04;
    color: rgba(191,26,4,0);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #navBtn {
    font-size: 2rem;
    min-width: 8rem;
    width: 10rem;
    border-bottom: 3px solid #ddd;

  }

  #navBtn.active {
    color: rgba(211,211,211,0);
    width: 30vw;
    border-bottom: 6px solid #ddd;
  }
  
  #navBtn:hover {
    border-bottom: 6px solid #bf1a04;
    color: rgba(191,26,4,1);
  }
  
  #navBtn.clicked {
    border-bottom: 6px solid #bf1a04;
    color: rgba(191,26,4,0);
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #navBtn {
    font-size: 2.5rem;
    width: 12.5rem;
    border-bottom: 3px solid #ddd;

  }

  #navBtn.active {
    color: rgba(211,211,211,0);
    width: 30vw;
    border-bottom: 6px solid #ddd;
  }
  
  #navBtn:hover {
    border-bottom: 6px solid #bf1a04;
    color: rgba(191,26,4,1);
  }
  
  #navBtn.clicked {
    border-bottom: 6px solid #bf1a04;
    color: rgba(191,26,4,0);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #navBtn {
    font-size: 3rem;
    min-width: 12rem;
    width: 15rem;
    border-bottom: 4px solid #ddd;

  }

  #navBtn.active {
    color: rgba(211,211,211,0);
    width: 30vw;
    border-bottom: 8px solid #ddd;
  }
  
  #navBtn:hover {
    border-bottom: 8px solid #bf1a04;
    color: rgba(191,26,4,1);
  }
  
  #navBtn.clicked {
    border-bottom: 8px solid #bf1a04;
    color: rgba(191,26,4,0);
  }
}
