.header-nav {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100vw;
}

.header-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.main-nav {
  position: fixed;
  width: 100vw;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  z-index: 2;
  transition: 0.4s;
  bottom: 30vh;
}

.main-nav.active {
  height: auto;
  bottom: 1rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .header-nav {
    height: 3rem;
  }

  .main-nav.active {
    bottom: 0.8rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .header-nav {
    height: 3.5rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .header-nav {
    height: 3.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .header-nav {
    height: 4rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .header-nav {
    height: 4.5rem;
  }
}