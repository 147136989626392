#stores {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: auto;
}

#stores::-webkit-scrollbar {
    display: none;
}

.store {
    color: white;
    font-family: "Courier New", Courier, monospace;
}

.store img{
    height: auto;
}

.text-box {
    height: auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #stores {
    flex-flow: row wrap;
    margin-top: 3.5rem;
    height: calc(100vh - 6rem);
  }
  
  .store {
      width: 90vw;
  }
  
  .store img{
      width: 80vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #stores {
    height: calc(100vh - 8rem);
    margin-top: 4rem;
    flex-flow: row wrap;

  }
  
  .store {
      width: 95vw;
  }
  
  .store img{
      width: 80vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #stores {
    height: calc(100vh - 8.5rem);
    margin-top: 4rem;
    flex-flow: row wrap;
  }
  
  .store {
    width: 100vw;
  }

  .store img{
    width: 25vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #stores {
    margin-top: 4.5rem;
    height: calc(100vh - 10rem);
  }
  
  .store {
    width: 45vw;
  }

  .store img{
    width: 25vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #stores {
    margin-top: 5rem;
    height: calc(100vh - 11.8rem);
    justify-content: space-evenly;
  }
  
  .store {
      width: 45vw;
  }
  
  .store img{
      width: 25vw;
  }
}