h1 {
  color: white;
}

.page {
  overflow: auto;
  scrollbar-width: none;
}

.page::-webkit-scrollbar {
  display: none;
}

.location-selector{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100);
}

.location-selector a {
  text-align: center;
  text-decoration: none;
  align-items: center;
}

.location-selector button {
  background-color: rgba(0,0,0,0);
  font-family: "Courier New", Courier, monospace;
  font-size: 5vh;
  border: none;
  color: #ddd;
}

.location-selector button:focus {
  outline: none;
}

.location-selector button:hover {
  color: rgba(191,26,4,1);
}

#menu-selector-btn {
  margin-bottom: 5vh;
}

.menu-category {
  font-size: 30px;
  font-family: "Courier New", Courier, monospace;
  color: rgba(221,221,221,1);
  background-color: rgba(0,0,0,0);
  margin: 20px 0 10px 0;
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: 0.4s;
}

.menu-category:focus{
  border: none;
  outline: none;
}

.menu-category.active,
.menu-category:hover{
  background-color: rgba(0,0,0,0);
  color: rgba(191,26,4,1);
  border: none;
}

.location-page {
  height: calc((var(--vh, 1vh) * 90) - 180px);
}

.react-slidedown.menu-slider {
  transition-duration: 1s;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .page {
    margin-top: 3.5rem;
    height: calc(100vh - 6rem);
  }

  #title {
    font-size: 25px;
  }

  #main-nav {
    margin-top: 30px;
  }

  #main-nav button {
    font-size: 10px;
    margin-right: 5px;
  }

  #main-nav button.active {
    font-size: 10px;
    margin-right: 5px;
  }

  #main-nav button.clicked,
  #main-nav button:hover {
    font-size: 20px;
  }

  .menu-category {
    font-size: 20px;
  }

  .location-selector button {
    font-size: 3.5vh;
  }

  .page-title {
    font-size: 4vh;
    height: 5vh;
    border-bottom: 8px double #ddd;
  }

  .menu-page,
  .location-page {
    height: calc(var(--vh, 1vh) * 100);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .page {
    margin-top: 4rem;
    height: calc(100vh - 8rem);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .page {
    margin-top: 4rem;
    height: calc(100vh - 8.5rem);
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .page {
    margin-top: 5rem;
    height: calc(100vh - 10rem);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .page {
    margin-top: 6.5rem;
    height: calc(100vh - 11.8rem);
  }
}