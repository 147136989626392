#morePage {
  height: calc(100vh - 120px);
  overflow: auto; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  transition: all 300ms ease-in-out;
}

#morePage::-webkit-scrollbar {
  display: none;
}

#morePage.active {
  padding-top: 0px;
}

#morePage p {
  color: #ddd;
  font-size: 1.5rem;
}

.moreBtn {
  background-color: rgba(0,0,0,0);
  color: #ddd;
  width: 100%;
  border: none;
  text-decoration: none;
  outline: none;
  min-height: 24px;
}

.moreBtn:hover {
  color: rgba(150,26,4,1);

}

.moreBtn:active {
  color: rgba(150,26,4,1);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #morePage {
    max-width: 550px;
    min-width: 0px;
    margin-top: 3rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #morePage {
    margin-top: 3.5rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #morePage {
    margin-top: 3.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #morePage {
    margin-top: 4rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #morePage {
    margin-top: 4.5rem;
  }
}